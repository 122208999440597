

import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import siding from '../assets/images/siding.jpg'
import LaborWarranty from '../components/LaborWarranty'

const Siding = (props) => (
    <Layout>
        <Helmet>
            <title>Siding (Vinyl, Lap, Fiber Cement, HardiePlank)</title>
            <meta name="description" content="We provide superior Siding services in the South Eastern part of the USA!" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Siding (Vinyl, Lap, Fiber Cement, HardiePlank)</h1>
                        <p>Pricing Varies by Type of Siding</p>
                    </header>
                    
                    <span className="image main">
                    <img src={siding} alt="" /></span><br /><br />
                    
                    <header className="major">
                        <h3>What's Included</h3>
                    </header>
                    <p>...</p><br /><br />
                    
                    <header className="major">
                        <h3>Pricing</h3>
                    </header>
                    <ul>
                        <li>How We Price It: </li>
                        <li>Competitor Pricing: </li>
                    </ul><br /><br />
                    
                    <header className="major">
                        <h3>Warranty Information</h3>
                    </header>
                    <ul>
                        <li>Materials Warranty: </li>
                        <li>{LaborWarranty}</li>
                    </ul><br />
                </div>
            </section>
        </div>

    </Layout>
)

export default Siding